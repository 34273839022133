import { defineNuxtPlugin } from '#app/nuxt'
import { LazyElementApplinksDefinitions, LazyElementApplinks, LazyElementButton, LazyElementCommonPaymentComponentsFormError, LazyElementCommonPaymentComponentsHr, LazyElementCommonPaymentComponents, LazyElementCommonPaymentComponentsPaymentErrors, LazyElementCommonPaymentComponentsPaymentFormInputs, LazyElementCommonPaymentComponentsPaymentFormStripe, LazyElementCommonPaymentComponentsPaymentMethodIcons, LazyElementCommonPaymentComponentsPriceInfo, LazyElementCommonPaymentComponentsProviderButton, LazyElementCommonPaymentComponentsProviderTabs, LazyElementCommonPaymentComposablesUsePaymentElement, LazyElementCommonPaymentTypes, LazyElementCommonPaymentUtils, LazyElementCommonRegistrationComponentsRegisterInput, LazyElementDateentryComponentsDateSelect, LazyElementDateentry, LazyElementDateentryUtils, LazyElementEndingprogressComponentsProgressSteps, LazyElementEndingprogress, LazyElementFileElementError, LazyElementFile, LazyElementImage, LazyElementImageselection, LazyElement, LazyElementLocationpicker, LazyElementPaymentResultDefinitions, LazyElementPaymentResult, LazyElementPaymentWithTabs, LazyElementPayment, LazyElementPlain, LazyElementPlanSelectionComponentsPlanItem, LazyElementPlanSelection, LazyElementRegistrationDefinitions, LazyElementRegistration, LazyElementSelection, LazyElementSinglerowtext, LazyElementSurveyprogress, LazyElementTestimonialsComponentsBlock, LazyElementTestimonialsComponentsStar, LazyElementTestimonials, LazyElementText, LazyElementTimeentryComponentsTimeSelect, LazyElementTimeentry, LazyElementTimeentryUtils, LazyElementTranslation, LazyElementVideo, LazyElementWrapper, LazySectionColumn, LazySectionDefinitions, LazySectionDefinitionsPayment, LazySectionDefinitionsPaymentSuccess, LazySectionDefinitionsPaywall, LazySectionDefinitionsProgress, LazySectionDefinitionsQuestion, LazySectionDefinitionsRegister, LazySectionDefinitionsSurveyEnding, LazySection } from '#components'
const lazyGlobalComponents = [
  ["ElementApplinksDefinitions", LazyElementApplinksDefinitions],
["ElementApplinks", LazyElementApplinks],
["ElementButton", LazyElementButton],
["ElementCommonPaymentComponentsFormError", LazyElementCommonPaymentComponentsFormError],
["ElementCommonPaymentComponentsHr", LazyElementCommonPaymentComponentsHr],
["ElementCommonPaymentComponents", LazyElementCommonPaymentComponents],
["ElementCommonPaymentComponentsPaymentErrors", LazyElementCommonPaymentComponentsPaymentErrors],
["ElementCommonPaymentComponentsPaymentFormInputs", LazyElementCommonPaymentComponentsPaymentFormInputs],
["ElementCommonPaymentComponentsPaymentFormStripe", LazyElementCommonPaymentComponentsPaymentFormStripe],
["ElementCommonPaymentComponentsPaymentMethodIcons", LazyElementCommonPaymentComponentsPaymentMethodIcons],
["ElementCommonPaymentComponentsPriceInfo", LazyElementCommonPaymentComponentsPriceInfo],
["ElementCommonPaymentComponentsProviderButton", LazyElementCommonPaymentComponentsProviderButton],
["ElementCommonPaymentComponentsProviderTabs", LazyElementCommonPaymentComponentsProviderTabs],
["ElementCommonPaymentComposablesUsePaymentElement", LazyElementCommonPaymentComposablesUsePaymentElement],
["ElementCommonPaymentTypes", LazyElementCommonPaymentTypes],
["ElementCommonPaymentUtils", LazyElementCommonPaymentUtils],
["ElementCommonRegistrationComponentsRegisterInput", LazyElementCommonRegistrationComponentsRegisterInput],
["ElementDateentryComponentsDateSelect", LazyElementDateentryComponentsDateSelect],
["ElementDateentry", LazyElementDateentry],
["ElementDateentryUtils", LazyElementDateentryUtils],
["ElementEndingprogressComponentsProgressSteps", LazyElementEndingprogressComponentsProgressSteps],
["ElementEndingprogress", LazyElementEndingprogress],
["ElementFileElementError", LazyElementFileElementError],
["ElementFile", LazyElementFile],
["ElementImage", LazyElementImage],
["ElementImageselection", LazyElementImageselection],
["Element", LazyElement],
["ElementLocationpicker", LazyElementLocationpicker],
["ElementPaymentResultDefinitions", LazyElementPaymentResultDefinitions],
["ElementPaymentResult", LazyElementPaymentResult],
["ElementPaymentWithTabs", LazyElementPaymentWithTabs],
["ElementPayment", LazyElementPayment],
["ElementPlain", LazyElementPlain],
["ElementPlanSelectionComponentsPlanItem", LazyElementPlanSelectionComponentsPlanItem],
["ElementPlanSelection", LazyElementPlanSelection],
["ElementRegistrationDefinitions", LazyElementRegistrationDefinitions],
["ElementRegistration", LazyElementRegistration],
["ElementSelection", LazyElementSelection],
["ElementSinglerowtext", LazyElementSinglerowtext],
["ElementSurveyprogress", LazyElementSurveyprogress],
["ElementTestimonialsComponentsBlock", LazyElementTestimonialsComponentsBlock],
["ElementTestimonialsComponentsStar", LazyElementTestimonialsComponentsStar],
["ElementTestimonials", LazyElementTestimonials],
["ElementText", LazyElementText],
["ElementTimeentryComponentsTimeSelect", LazyElementTimeentryComponentsTimeSelect],
["ElementTimeentry", LazyElementTimeentry],
["ElementTimeentryUtils", LazyElementTimeentryUtils],
["ElementTranslation", LazyElementTranslation],
["ElementVideo", LazyElementVideo],
["ElementWrapper", LazyElementWrapper],
["SectionColumn", LazySectionColumn],
["SectionDefinitions", LazySectionDefinitions],
["SectionDefinitionsPayment", LazySectionDefinitionsPayment],
["SectionDefinitionsPaymentSuccess", LazySectionDefinitionsPaymentSuccess],
["SectionDefinitionsPaywall", LazySectionDefinitionsPaywall],
["SectionDefinitionsProgress", LazySectionDefinitionsProgress],
["SectionDefinitionsQuestion", LazySectionDefinitionsQuestion],
["SectionDefinitionsRegister", LazySectionDefinitionsRegister],
["SectionDefinitionsSurveyEnding", LazySectionDefinitionsSurveyEnding],
["Section", LazySection],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
