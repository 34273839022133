import { createSection, createElement, createResponsiveFontSizeOption } from '@shared/utils/section';

export const paymentSection1 = createSection({
  type: 'payment',
  name: 'Payment Body',
  uniqName: 'PaymenyBody1',
  options: {
    visible: true,
    removable: false,
    hideable: false
  },
  attr: { class: 'px-4 sm:px-20 py-32' },
})
  .column({ colspan: 12 })
  .element({
    type: 'plain',
    attr: { class: 'px-16' },
    elements: [
      createElement({
        type: 'text',
        name: 'Text 1',
        value: 'Grab your offer now',
        options: {
          visible: true,
          ...createResponsiveFontSizeOption({ mobile: 20, tablet: 20 })
        },
        style: {
          fontWeight: 'bold',
          fontSize: '20px',
          textAlign: 'center',
          marginBottom: '8px'
        }
      }),
      createElement({
        type: 'text',
        name: 'Text 2',
        value: 'Please provide payment details to continue',
        options: {
          visible: true,
          ...createResponsiveFontSizeOption({ mobile: 16, tablet: 16 })
        },
        attr: { class: '!mb-[10px] sm:!mb-[40px]' },
        style: {
          fontSize: '16px',
          textAlign: 'center'
        }
      })
    ]
  })
  .element({
    type: 'plain',
    attr: { class: 'flex flex-col sm:flex-row justify-center gap-[24px] mx-auto' },
    style: { maxWidth: '944px' },
    elements: [
      createElement({
        type: 'image',
        name: 'Image',
        value: '/editor/anil-kumar-unsplash.jpg',
        attr: { class: '!hidden sm:!flex sm:!flex-[0_1_460px] !h-full !w-full has-fixed-height-image' },
        wrapperClass: '!hidden sm:!flex sm:!flex-[0_1_460px]',
        style: { borderRadius: '6px', height: '100%', objectFit: 'cover' },
        options: { visible: true, fullSize: true }
      }),
      createElement({
        type: 'payment',
        name: 'Payment Form',
        attr: { class: 'sm:!flex-[0_1_460px]' },
        wrapperClass: 'sm:!flex-[0_1_460px]',
        style: {
          borderWidth: '1px',
          borderColor: '#E7EAEE',
          borderRadius: '6px',
          backgroundColor: '#FFFFFF',
          display: 'flex',
          alignItems: 'center'
        },
        options: {
          visible: true,
          hideable: false
        },
        elements: [
          createElement({
            type: 'button',
            value: 'Start 7-days Trial',
            tagName: 'button',
            attr: { class: 'w-full', type: 'submit' },
            options: {
              visible: true,
              hideable: false
            },
            style: {
              minHeight: '48px',
              color: '',
              fontWeight: 'bold',
              fontSize: '14px',
              backgroundColor: '',
              borderRadius: '',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              hover: {
                backgroundColor: ''
              }
            }
          }),
          createElement({
            type: 'text',
            slotName: 'infoText',
            value:
              'You will be charged <b style="color:#0D0626;">{TRIAL_PRICE} for {TRIAL_PERIOD}</b>, then {PRICE} per {PERIOD}.',
            options: {
              visible: true,
              allowLinks: true,
              customizeFontColor: true,
              ...createResponsiveFontSizeOption({ mobile: 10, tablet: 10 })
            },
            style: {
              fontSize: '10px',
              textAlign: 'left',
              color: '#737380'
            }
          }),
          createElement({
            type: 'text',
            slotName: 'infoText2',
            value:
              'By clicking the button, you agree to have read our <a href="#" style="color: #007AFF;">Terms of Use</a> and <a href="#" style="color: #007AFF;">Privacy Policy</a> and acknowledge that you are over 18 years old.',
            options: {
              visible: true,
              allowLinks: true,
              customizeFontColor: true,
              ...createResponsiveFontSizeOption({ mobile: 10, tablet: 10 })
            },
            style: {
              fontSize: '10px',
              textAlign: 'left',
              color: '#737380'
            }
          })
        ]
      })
    ]
  })
  .done()
  .done();

export const paymentWithTabsSection = createSection({
  type: 'payment',
  name: 'Payment Body',
  uniqName: 'PaymenyBody2',
  options: {
    visible: true,
    removable: false,
    hideable: false
  },
  attr: { class: 'px-4 sm:px-20 py-32' },
  style: {
    backgroundColor: '#FFFFFF'
  }
})
  .column({ colspan: 12 })
  .element({
    type: 'plain',
    attr: { class: 'px-16' },
    elements: [
      createElement({
        type: 'text',
        name: 'Text 1',
        value: 'Grab your offer now',
        options: {
          visible: true,
          ...createResponsiveFontSizeOption({ mobile: 20, tablet: 20 })
        },
        style: {
          fontWeight: 'bold',
          fontSize: '20px',
          textAlign: 'center',
          marginBottom: '8px'
        }
      }),
      createElement({
        type: 'text',
        name: 'Text 2',
        value: 'Please provide payment details to continue',
        options: {
          visible: true,
          ...createResponsiveFontSizeOption({ mobile: 16, tablet: 16 })
        },
        attr: { class: '!mb-[10px] sm:!mb-[40px]' },
        style: {
          fontSize: '16px',
          textAlign: 'center'
        }
      })
    ]
  })
  .element({
    type: 'plain',
    attr: { class: 'flex flex-col sm:flex-row justify-center gap-[24px] mx-auto' },
    style: { maxWidth: '944px' },
    elements: [
      createElement({
        type: 'image',
        name: 'Image',
        value: '/editor/anil-kumar-unsplash.jpg',
        attr: { class: '!hidden sm:!flex sm:!flex-[0_1_460px] !h-full !w-full min-h-[600px] has-fixed-height-image' },
        wrapperClass: '!hidden sm:!flex sm:!flex-[0_1_460px]',
        style: { borderRadius: '6px', height: '100%', objectFit: 'cover' },
        options: { visible: true, fullSize: true }
      }),
      createElement({
        type: 'payment-with-tabs',
        name: 'Payment Form',
        attr: { class: 'sm:!flex-[0_1_460px]' },
        wrapperClass: 'sm:!flex-[0_1_460px]',
        style: {
          borderWidth: '1px',
          borderColor: '#E7EAEE',
          borderRadius: '6px',
          backgroundColor: '#FFFFFF',
          display: 'flex',
          alignItems: 'center'
        },
        options: {
          visible: true,
          hideable: false
        },
        elements: [
          createElement({
            type: 'button',
            value: 'PAY NOW',
            tagName: 'button',
            attr: { class: 'w-full', type: 'submit' },
            options: {
              visible: true,
              hideable: false
            },
            style: {
              minHeight: '48px',
              color: '',
              fontWeight: 'bold',
              fontSize: '14px',
              backgroundColor: '',
              borderRadius: '',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              hover: {
                backgroundColor: ''
              }
            }
          }),
          createElement({
            type: 'text',
            slotName: 'infoText',
            value:
              'You will be charged {TRIAL_PRICE} for {TRIAL_PERIOD}, then {PRICE} per {PERIOD}. By clicking the button, you agree to have read our <a href="#" style="color: #007AFF;">Terms of Use</a> and <a href="#" style="color: #007AFF;">Privacy Policy</a> and acknowledge that you are over 18 years old.',
            options: {
              visible: true,
              allowLinks: true,
              customizeFontColor: true,
              ...createResponsiveFontSizeOption({ mobile: 11, tablet: 11 })
            },
            style: {
              fontSize: '11px',
              textAlign: 'left',
              color: '#737380'
            }
          })
        ]
      })
    ]
  })
  .done()
  .done();
