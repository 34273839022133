import { onMounted, onUnmounted, type Ref, ref, computed, type ComputedRef, watch, nextTick, onBeforeUnmount } from 'vue';
import IMask from 'imask';
import Countries, { type Country } from 'countries-phone-masks';

type UseMaskOptions = IMask.AnyMaskedOptions & { el?: Ref<any>; value?: string | number };
type UseNumericOptions = {
  el: Ref<HTMLElement>;
  props: Readonly<any>;
  value?: string | number;
  mask?: Ref<IMask.InputMask<IMask.AnyMaskedOptions>>;
  masked?: Ref<string>;
  unmasked?: Ref<string>;
};

type UsePhoneOptions = UseNumericOptions;

type UseNumericResponse = {
  el: Ref<HTMLElement>;
  mask: Ref<IMask.InputMask<IMask.AnyMaskedOptions>>;
  masked: Ref<string>;
  unmasked: Ref<string>;
};

type UseMaskResponse = UseNumericResponse & {
  typed: Ref<string | number | Date>;
  updateOptions: () => void;
};

type UsePhoneResponse = UseNumericResponse & {
  unmaskedWithCode: ComputedRef<string>;
  maskPattern: ComputedRef<string | { mask: string; }[]>;
  country: ComputedRef<Country | undefined>;
  currentCountry: Ref<string | undefined>;
};

export function useMask(options: UseMaskOptions): UseMaskResponse {
  const el = options.el || ref();
  const mask = ref() as Ref<IMask.InputMask<IMask.AnyMaskedOptions>>;
  const typed = ref();
  const masked = ref();
  const unmasked = ref();
  const defaultValue = `${options.value}`;

  function setDefaultValue() {
    if (mask?.value && defaultValue) {
      mask.value.unmaskedValue = defaultValue;
    }
  }

  function _onAccept() {
    typed.value = mask.value.typedValue;
    masked.value = mask.value.value;
    unmasked.value = mask.value.unmaskedValue;
  }

  function _destroyMask() {
    mask.value?.destroy();
  }

  function updateOptions() {
    mask.value?.updateOptions(options);
  }

  function _initMask() {
    delete options.value;
    const $el = el.value;
    mask.value = IMask($el, options).on('accept', _onAccept).on('completed', _onAccept);
    mask.value.updateValue();
    setDefaultValue();
  }

  onMounted(_initMask);
  onUnmounted(_destroyMask);

  return {
    el,
    mask,
    typed,
    masked,
    unmasked,
    updateOptions
  };
}

export function getCountryCodeByNumber(phoneNumber: string | number, matchLength = true): string {
  const phoneNumberLength = `${phoneNumber}`.length;
  const country = Countries.find((item: Record<any, any>) => {
    const code = item.code.replace(/\D/g, '');
    const mask = (Array.isArray(item.mask) ? item.mask[0] : item.mask).replace(/(-|\(|\))/g, '');
    const isMatchingLength = matchLength ? `${code}${mask}`.length === phoneNumberLength : true;
    const codeByItem = `${phoneNumber}`.substring(0, code.length);
    return isMatchingLength && code === codeByItem;
  });

  return country?.iso || '';
}

export function getCountryByCode(code: string): (Country & { minMaskLength: number, maxMaskLength: number }) | undefined {
  const item = Countries.find((country: any) => country.iso === code);
  if (!item) return;

  return {
    ...item,
    ...getPhoneMaskLengths(item.mask, item.code)
  };
}

function getPhoneMaskLengths(mask: string | string[], countryCode: string) {
  let minMaskLength = 0;
  let maxMaskLength = 0;

  function getClearMask(mask: string) {
    return (countryCode + mask).replace(/(-|\(|\)|\+)/g, '');
  }

  if (Array.isArray(mask)) {
    const { minLength, maxLength } = mask.reduce(
      (acc, current) => {
        const currentMaskLength = getClearMask(current).length;
        return {
          minLength: Math.min(acc.minLength, currentMaskLength),
          maxLength: Math.max(acc.maxLength, currentMaskLength)
        };
      },
      { minLength: Infinity, maxLength: 0 }
    );
    minMaskLength = minLength;
    maxMaskLength = maxLength;
  } else {
    const maskLength = getClearMask(mask).length;
    minMaskLength = maskLength;
    maxMaskLength = maskLength;
  }

  return {
    minMaskLength,
    maxMaskLength
  };
}

function getMaskByCountry(country?:Country) {
  if (!country) return '';
  let mask = country.code.replace(/0/g, '\\0') + ' ';
  if (Array.isArray(country.mask)) {
    const masks = country.mask || [];
    const masksObj = masks.map(currentMask => {
      return {
        mask: (mask + currentMask).replace(/#/g, '0')
      }
    })
    return masksObj as any
  } else {
    mask += country.mask;
    return mask.replace(/#/g, '0');
  }
}

export function getMaskedPhoneNumber(phoneNumber:string) {
  const code = getCountryCodeByNumber(phoneNumber, false);
  const country = getCountryByCode(code);
  const mask = getMaskByCountry(country);
  const maskInstance = IMask.createMask({ mask, lazy: false });
  return maskInstance.resolve(phoneNumber);
}

export function usePhone(options: UsePhoneOptions): UsePhoneResponse {
  const el = options.el || ref();
  let mask = options.mask;
  let masked = options.masked || ref('');
  let unmasked = options.unmasked || ref('');
  const $props = options.props;
  const currentCountry = ref((options.value ? getCountryCodeByNumber(options.value) : $props.country));

  const country = computed(() => {
    return Countries.find((item: any) => item.iso === currentCountry.value);
  });

  const maskPattern = computed(() => {
    if ($props.type !== 'phone') return '';
    return getMaskByCountry(country?.value);
  });

  if (!mask) {
    const maskRef = useMask({
      el,
      mask: maskPattern.value,
      value: options.value || ''
    });

    mask = maskRef.mask;
    masked = maskRef.masked;
    unmasked = maskRef.unmasked;
  }

  const unmaskedWithCode = computed(() => {
    return masked.value?.replace(/(\D)/g, '');
  });

  watch(
    () => $props.country,
    (code) => {
      currentCountry.value = code;
      mask?.value.updateOptions({
        mask: maskPattern.value
      });

      nextTick(() => {
        el.value?.focus();
        el.value?.blur();
      });
    }
  );

  watch(
    () => currentCountry.value,
    () => {
      mask?.value.updateOptions({
        mask: maskPattern.value
      });

      nextTick(() => {
        el.value?.focus();
        el.value?.blur(); // triggers validation with blur
        el.value?.focus();
      });
    }
  );

  function setDefaultValue() {
    if (mask?.value) {
      const countryCode = country.value?.code.replace(/\D/g, '');
      const numberWithoutCode = `${options.value}`?.slice(countryCode?.length);
      mask.value.unmaskedValue = numberWithoutCode as string;
    }
  }

  onMounted(() => {
    setDefaultValue();
  });

  return {
    el,
    mask,
    masked,
    unmasked,
    unmaskedWithCode,
    maskPattern,
    country,
    currentCountry
  };
}

export function getCardMask(value: string) {
  const cardTypes = [
    {
      name: "American Express",
      mask: "0000 000000 00000",
      regex: "^3[47]\\d{0,13}",
      length: 15,
      icon: "AmEx"
    },
    {
      name: "Diners Club Carte Blanche",
      mask: "0000 000000 0000",
      regex: "^300\\d{0,11}|^301\\d{0,11}|^302\\d{0,11}|^303\\d{0,11}|^304\\d{0,11}|^305\\d{0,11}",
      length: 14,
      icon: "DinersClub",
    },
    {
      name: "Diners Club International",
      mask: "0000 000000 0000",
      regex: "^36\\d{0,12}",
      length: 14,
      icon: "DinersClub",
    },
    {
      name: "Discover Card",
      mask: "0000 0000 0000 0000",
      regex: "^6(?:011|5|4[4-9]|22(?:1(?:2[6-9]|[3-9]\\d)|[2-8]|9(?:[01]\\d|2[0-5])))",
      length: 16,
      icon: "Discover",
    },
    {
      name: "JCB",
      mask: "0000 0000 0000 0000",
      regex: "^(?:2131|1800|35\\d{0,3})\\d{0,11}",
      length: 16,
      icon: "JCB",
    },
    {
      name: "Maestro",
      mask: "0000 0000 0000 0000",
      regex: "^(?:5[0678]\\d\\d|6304|6390|67\\d\\d)\\d{0,13}",
      length: 16,
      icon: "Maestro",
    },
    {
      name: "MasterCard",
      mask: "0000 0000 0000 0000",
      regex: "^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]",
      length: 16,
      icon: "MasterCard",
    },
    {
      name: "Visa",
      mask: "0000 0000 0000 0000",
      regex: "^4\\d{0,15}",
      length: 16,
      icon: "Visa",
    },
    {
      name: "China UnionPay",
      mask: "0000 0000 0000 0000",
      regex: "^62\\d{0,14}",
      length: 16,
      icon: "UnionPay",
    },
    {
      name: "Mir",
      mask: "0000 0000 0000 0000",
      regex: "^(?:220[0-4])\\d{0,12}",
      length: 16,
      icon: "Mnp",
    }
  ];

  const card = cardTypes.find((card) => {
    return new RegExp(card.regex, "g").test(value);
  })


  return card || {
    name: "Default",
    mask: "0000 0000 0000 0000",
    length: 16,
    regex: "",
    icon: ""
  };
}

export function useCard(options: UsePhoneOptions) {
  const el = options.el || ref();
  let mask = options.mask;
  let masked = options.masked || ref('');
  let unmasked = options.unmasked || ref('');
  const currentMask = ref({ name: "Default", mask: "0000 0000 0000 0000" });

  if (!mask) {
    const maskRef = useMask({
      el,
      mask: currentMask.value.mask
    });

    mask = maskRef.mask;
    masked = maskRef.masked;
    unmasked = maskRef.unmasked;
  }

  watch(
    () => unmasked.value,
    (val) => {
      currentMask.value = getCardMask(val);

      mask?.value.updateOptions({
        mask: currentMask.value.mask
      });
    }
  );

  return {
    el,
    mask,
    masked,
    unmasked,
    currentMask
  }
}

export function useNumeric(options: UseNumericOptions): UseNumericResponse {
  const el = options.el || ref();
  const props = options.props || {};
  const min = parseFloat(props.min || '0');
  const max = parseFloat(props.max || '0');
  const stepCount = props.step;
  const defaultVal = `${options.value || '' + (min === Number.MIN_SAFE_INTEGER ? 0 : min) || 0}`;
  let mask = options.mask;
  let masked = options.masked || ref('');
  let unmasked = options.unmasked || ref('');

  if (!mask) {
    const maskRef = useMask({
      el,
      min,
      max,
      mask: Number,
      autofix: 'pad'
    });

    mask = maskRef.mask;
    masked = maskRef.masked;
    unmasked = maskRef.unmasked;
  }

  function setDefaultValue() {
    if (mask?.value) {
      mask.value.unmaskedValue = defaultVal;
    }
  }

  // Makes up & down navigations
  function onKeyDown(e: any) {
    if (!mask?.value) return false;

    const keyCode = e.keyCode;
    const up = keyCode === 38;
    const down = keyCode === 40;
    const shift = e.shiftKey || false;
    const step = shift ? stepCount : 1; // Add/extract up to step if press shift key

    if (up) {
      const newVal = parseInt(mask?.value.unmaskedValue || '' + (max === Number.MAX_SAFE_INTEGER ? 0 : max)) + step;
      mask.value.unmaskedValue = `${max && newVal > max ? max : newVal}` as string;
    } else if (down) {
      const newVal = parseInt(mask?.value.unmaskedValue || '' + (min === Number.MIN_SAFE_INTEGER ? 0 : min)) - step;
      mask.value.unmaskedValue = `${newVal < min ? min : newVal}` as string;
    }
  }

  function onInputFocus() {
    el.value.addEventListener('keydown', onKeyDown);
  }

  function onInputBlur() {
    el.value.removeEventListener('keydown', onKeyDown);
  }

  onMounted(() => {
    setDefaultValue();
    el.value?.addEventListener('focus', onInputFocus);
    el.value?.addEventListener('blur', onInputBlur);
  });

  onBeforeUnmount(() => {
    el.value?.removeEventListener('focus', onInputFocus);
    el.value?.removeEventListener('blur', onInputBlur);
  });

  return {
    el,
    mask,
    masked,
    unmasked
  };
}

export function useNoMaskNumeric(payload: { value: Ref<any>; props: Readonly<any> }) {
  const { value } = payload;
  const props = payload.props || { min: '0', max: '0' };
  const min = parseFloat(props.min);
  const max = parseFloat(props.max);
  const step = props.step || 10; // Add/extract up to step if press shift key

  // Makes up & down navigations
  function onKeyDown(e: KeyboardEvent) {
    const shift = e.shiftKey || false;
    if (!shift || step <= 1) return;

    const keyCode = e.keyCode;
    const up = keyCode === 38;
    const down = keyCode === 40;

    if (up) {
      const newVal = parseInt(value.value || '' + (max === Number.MAX_SAFE_INTEGER ? 0 : max)) + step - 1;
      value.value = `${max && newVal > max ? max : newVal}`;
    } else if (down) {
      const newVal = parseInt(value?.value || '' + (min === Number.MIN_SAFE_INTEGER ? 0 : min)) - step + 1;
      value.value = `${newVal < min ? min : newVal}`;
    }
  }

  function isInRange(value: string) {
    const min = props.min;
    const max = props.max;
    const val = parseFloat(value);
    return val >= min && val <= max;
  }

  function formatNumber(value: string) {
    const min = props.min;
    const max = props.max;
    const val = parseFloat(value);
    const intValue = val < min ? min : val > max ? max : val;
    return `${intValue}`;
  }

  function checkNumberForRange() {
    if (props.type === 'number' && props.noMask && !isInRange(value.value)) {
      value.value = formatNumber(value.value);
    }
  }

  return {
    value,
    onKeyDown,
    isInRange,
    formatNumber,
    checkNumberForRange
  };
}

export const countries = Countries;
