import { createSection, createResponsiveFontSizeOption } from '@shared/utils/section';

export const surveyEndingSection = createSection({
  type: 'surveyending',
  name: 'Survey Ending',
  uniqName: 'SurveyEnding1',
  attr: { class: 'py-24 px-20 md:py-32' },
  options: {
    visible: true,
    removable: false,
    hideable: false
  }
})
  .column({ colspan: 12 })
  .element({
    type: 'text',
    value: 'Thank you for the information you have provided',
    name: 'Title',
    options: {
      visible: true,
      ...createResponsiveFontSizeOption({ tablet: 20, mobile: 16 })
    },
    attr: { class: '!text-16 md:!text-20' },
    style: {
      fontWeight: '600',
      textAlign: 'center',
      marginBottom: '24px',
      lineHeight: '20px'
    }
  })
  .element({
    type: 'text',
    value: 'Your personalized plan is being prepared',
    name: 'Description',
    options: {
      visible: true,
      ...createResponsiveFontSizeOption({ tablet: 16, mobile: 14 })
    },
    attr: { class: '!text-14 md:!text-16' },
    style: {
      fontWeight: '600',
      textAlign: 'center',
      lineHeight: '1.5 !important'
    }
  })
  .element({
    type: 'endingprogress',
    style: { marginTop: '64px', marginBottom: '32px' },
    options: {
      visible: true,
      removable: false,
      hideable: false,
      bar: {
        customize: false,
        bgColor: '#E5E5EA',
        trackColor: ''
      }
    }
  })
  .element({
    type: 'text',
    name: 'progressText',
    value: 'Please wait...',
    options: {
      visible: true,
      customizeFontColor: true,
      ...createResponsiveFontSizeOption({ tablet: 14, mobile: 14 })
    },
    style: {
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: '400',
      color: ''
    }
  })
  .done()
  .done();
