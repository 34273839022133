const logSyle =
  "background: #2E495E;border-radius: 0.5em;color: white;font-weight: bold;padding: 2px 0.5em;";

export const Facebook = {
  options: {
    id: null as null | string | number,
    debug: false,
  },

  log(...args: any[]) {
    if (this.options.debug && !process.server) {
      console.log("%cFacebook Pixel", logSyle, ...args);
    }
  },
  
  event(...args: any[]) {
    (globalThis as any)?.fbq?.(...args);
    this.log("event", args);
  },

  track(...args: any[]) {
    this.event('track', ...args);
  },
  
  purchase(payload: {
    value: any;
    currency: any;
    orderID: any;
    eventID?: any;
  }) {
    this.track('Purchase', {
      value: payload.value,
      currency: payload.currency
    }, {
      eventID: payload.eventID,
      orderID: payload.orderID,
    })
  },

  init(payload: { pixelId: number | string; debug?: boolean }) {
    const headScripts = {
      script: [] as any[],
      noscript: [] as any[]
    };

    if (process.server) return headScripts;
    if (this.options.id) return headScripts;
    if (!payload.pixelId) {
      console.warn("Facebook Pixel cannot be installed because there is no Pixel ID!");
      return headScripts;
    }

    this.options.id = payload.pixelId;
    this.options.debug = !!payload.debug;

    if (this.options.debug) {
      this.event('init', this.options.id);
      this.track('PageView');
      return headScripts;
    }

    if (globalThis.document) {
      (function(f: any,b: any, e: any, v: any) {
        if (f.fbq) return;
        const n: any = f.fbq = function(...args: any[]){
          n.callMethod
            // eslint-disable-next-line prefer-spread
            ? n.callMethod.apply(n, args)
            : n.queue.push(args)
          };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        const t = b.createElement(e);
        t.async=!0;
        t.src=v;
        const s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');
      this.event('init', this.options.id);
      this.track('PageView');
    }

    headScripts.noscript.push({
      body: true,
      innerHTML: `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${this.options.id}&ev=PageView&noscript=1" />`
    })

    return headScripts
  },

  initOnSSR(payload: { pixelId: number | string; debug?: boolean }) {
    const headScripts = {
      script: [] as any[],
      noscript: [] as any[]
    };

    if (!payload.pixelId) {
      console.warn("Facebook Pixel cannot be installed because there is no Pixel ID!");
      return headScripts;
    }

    this.options.id = payload.pixelId;
    this.options.debug = !!payload.debug;

    if (this.options.debug) {
      this.event('init', this.options.id);
      this.track('PageView');
      return headScripts;
    }

    headScripts.script.push({
      innerHTML: `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${this.options.id}');
      fbq('track', 'PageView');`,
    });

    headScripts.noscript.push({
      body: true,
      innerHTML: `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${this.options.id}&ev=PageView&noscript=1" />`
    })

    return headScripts;
  }
}

