import { storeToRefs } from "pinia";
import { GTM } from "./gtm"
import { Facebook } from "./facebook"
import { type SiteData, useSiteStore } from "@/stores/site";

export const Integration = {
  isProd: () => useRuntimeConfig().public.NUXT_PROD,
  init() {
    const { siteData, integrations } = storeToRefs(useSiteStore());
    const headScripts = {
      script: [] as any[],
      noscript: [] as any[]
    };

    const cookieConsent = useCookie('cookieConsent')

    if (!siteData.value || siteData.value?.preview) return headScripts;
    
    const { gtmData, gaData } = integrations.value as SiteData['integrations'];

    if (gtmData?.isActive && !!gtmData?.gtmCode) {
      const scripts = GTM.initOnSSR({
        id: gtmData?.gtmCode,
        debug: !this.isProd(),
      });

      headScripts.script.push(...scripts.script);
      headScripts.noscript.push(...scripts.noscript);
    }

    if (gaData?.isActive && !!gaData?.gaCode) {
      const scripts = GTM.initGA4OnSSR({
        gaCode: gaData?.gaCode,
        debug: !this.isProd(),
      });

      headScripts.script.push(...scripts.script);
    }

    // Init if user access granted on cookie popup
    if (cookieConsent.value) {
      const metaScripts = this.meta(true);

      headScripts.script.push(...metaScripts.script);
      headScripts.noscript.push(...metaScripts.noscript);
    }

    return headScripts;
  },
  meta(ssr = false) {
    const { integrations } = storeToRefs(useSiteStore());
    const { facebookData } = integrations.value as SiteData['integrations'];

    const headScripts = {
      script: [] as any[],
      noscript: [] as any[]
    };

    if (facebookData?.isActive && !!facebookData?.pixelId) {
      const payload = {
        pixelId: facebookData.pixelId,
        debug: !this.isProd(),
      };

      const scripts = ssr ? Facebook.initOnSSR(payload) : Facebook.init(payload);

      headScripts.script.push(...scripts.script);
      headScripts.noscript.push(...scripts.noscript);
    }

    return headScripts;
  }
}

export * from './gtm';
export * from './facebook';
