import { createSection, createElement, createResponsiveFontSizeOption } from '@shared/utils/section';

export const questionSection1 = createSection({
  type: 'question',
  name: 'Survey Body',
  uniqName: 'SurveyBodySelection1',
  attr: { class: 'pb-24 pt-[12px] px-20 md:py-32' },
})
  .column({ colspan: 12 })
  .element({
    type: 'text',
    value: 'Question Title',
    options: { visible: true, isTitle: true, ...createResponsiveFontSizeOption({tablet: 20, mobile: 20}) },
    style: {
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      marginBottom: '16px'
    }
  })
  .element({
    type: 'text',
    value: 'Description',
    options: { visible: true, isDesc: true, ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}) },
    style: {
      fontSize: '16px',
      textAlign: 'center',
      marginBottom: '32px'
    }
  })
  .element({
    type: 'selection',
    style: { hover: {} },
    options: {
      visible: true,
      columns: { default: 4, max: 6 },
      buttons: {
        fontFamily: '',
        fontSize: 14,
        color: { regular: '', hover: '', selected: '' },
        textAlign: 'center',
        textStyle: { bold: false, italic: false, underline: false, strikeThrough: false },
        background: { regular: '#FFFFFF', hover: '#FFFFFF', selected: '' },
        border: { regular: '#B4BFC5', hover: '', selected: '', width: 1, radius: 0 }
      }
    },
    attr: {
      options: [
        {
          name: 'Option',
          type: 1,
          image: ''
        },
        {
          name: 'Option',
          type: 1,
          image: ''
        }
      ]
    },
    elements: [
      createElement({
        type: 'button',
        value: 'Continue',
        attr: { class: 'w-full md:w-[280px]' },
        style: {
          minHeight: '48px',
          color: '',
          fontWeight: 'bold',
          fontSize: '14px',
          backgroundColor: '',
          borderRadius: '',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          hover: {
            backgroundColor: ''
          }
        }
      })
    ]
  })
  .done()
  .done();

export const questionSection2 = createSection({
  type: 'question',
  name: 'Survey Body',
  uniqName: 'SurveyBodySelection2',
  attr: { class: 'md:px-[20px] md:pr-0 md:py-[32px]' },
})
  .column({
    colspan: 6,
    attr: {
      class: 'px-20 pt-[32px] pb-[48px] md:pr-[56px] md:py-0 md:flex md:flex-col md:justify-center'
    }
  })
  .element({
    type: 'plain',
    attr: { class: 'md:px-[10px]' },
    elements: [
      createElement({
        type: 'text',
        value: 'Question Title',
        options: { visible: true, isTitle: true, ...createResponsiveFontSizeOption({tablet: 20, mobile: 20}) },
        style: {
          fontWeight: 'bold',
          fontSize: '20px',
          textAlign: 'center',
          marginBottom: '16px'
        }
      }),
      createElement({
        type: 'text',
        value: 'Description',
        options: { visible: true, isDesc: true, ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}) },
        style: {
          fontSize: '16px',
          textAlign: 'center',
          marginBottom: '32px'
        }
      })
    ]
  })
  .element({
    type: 'selection',
    style: { hover: {} },
    options: {
      visible: true,
      columns: { default: 1, max: 4 },
      buttons: {
        fontFamily: '',
        fontSize: 14,
        color: { regular: '', hover: '', selected: '' },
        textAlign: 'center',
        textStyle: { bold: false, italic: false, underline: false, strikeThrough: false },
        background: { regular: '#FFFFFF', hover: '#FFFFFF', selected: '' },
        border: { regular: '#B4BFC5', hover: '', selected: '', width: 1, radius: 0 }
      }
    },
    attr: {
      options: [
        {
          name: 'Option',
          type: 1,
          image: ''
        },
        {
          name: 'Option',
          type: 1,
          image: ''
        }
      ]
    },
    elements: [
      createElement({
        type: 'button',
        value: 'Continue',
        attr: { class: 'w-full md:w-[280px]' },
        style: {
          minHeight: '48px',
          color: '',
          fontWeight: 'bold',
          fontSize: '14px',
          backgroundColor: '',
          borderRadius: '',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          hover: {
            backgroundColor: ''
          }
        }
      })
    ]
  })
  .done()
  .column({
    colspan: 6
  })
  .element({
    type: 'image',
    name: 'Image',
    value: '/editor/anil-kumar-unsplash.jpg',
    style: { width: '100%' },
    options: { visible: true, fullSize: true }
  })
  .done()
  .done();

export const questionSection3 = createSection({
  type: 'question',
  name: 'Survey Body',
  uniqName: 'SurveyBodySelection3',
  attr: { class: 'pb-[32px] md:px-[20px] md:pl-0 md:py-[32px]' }
})
  .column({
    colspan: 6,
    attr: { class: 'pb-[38px] md:pb-0 md:flex md:flex-col md:justify-center' }
  })
  .element({
    type: 'image',
    name: 'Image',
    value: '/editor/anil-kumar-unsplash.jpg',
    style: { width: '100%' },
    options: { visible: true, fullSize: true }
  })
  .done()
  .column({
    colspan: 6,
    attr: { class: 'px-20 md:pr-0 md:pl-[56px] md:flex md:flex-col md:justify-center' }
  })
  .element({
    type: 'plain',
    attr: { class: 'md:px-[10px]' },
    elements: [
      createElement({
        type: 'text',
        value: 'Question Title',
        options: { visible: true, isTitle: true, ...createResponsiveFontSizeOption({tablet: 20, mobile: 20}) },
        style: {
          fontWeight: 'bold',
          fontSize: '20px',
          textAlign: 'center',
          marginBottom: '16px'
        }
      }),
      createElement({
        type: 'text',
        value: 'Description',
        options: { visible: true, isDesc: true, ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}) },
        style: {
          fontSize: '16px',
          textAlign: 'center',
          marginBottom: '32px'
        }
      })
    ]
  })
  .element({
    type: 'selection',
    style: { hover: {} },
    options: {
      visible: true,
      columns: { default: 1, max: 4 },
      buttons: {
        fontFamily: '',
        fontSize: 14,
        color: { regular: '', hover: '', selected: '' },
        textAlign: 'center',
        textStyle: { bold: false, italic: false, underline: false, strikeThrough: false },
        background: { regular: '#FFFFFF', hover: '#FFFFFF', selected: '' },
        border: { regular: '#B4BFC5', hover: '', selected: '', width: 1, radius: 0 }
      }
    },
    attr: {
      options: [
        {
          name: 'Option',
          type: 1,
          image: ''
        },
        {
          name: 'Option',
          type: 1,
          image: ''
        }
      ]
    },
    elements: [
      createElement({
        type: 'button',
        value: 'Continue',
        attr: { class: 'w-full md:w-[280px]' },
        style: {
          minHeight: '48px',
          color: '',
          fontWeight: 'bold',
          fontSize: '14px',
          backgroundColor: '',
          borderRadius: '',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          hover: {
            backgroundColor: ''
          }
        }
      })
    ]
  })
  .done()
  .done();

export const questionSection4 = createSection({
  type: 'question',
  name: 'Survey Body',
  uniqName: 'SurveyBodyImageSelection1',
  attr: { class: 'py-24 px-20 md:py-32' }
})
  .column({ colspan: 12 })
  .element({
    type: 'text',
    name: 'Text 1',
    value: 'Question Title',
    options: { visible: true, isTitle: true, ...createResponsiveFontSizeOption({tablet: 20, mobile: 20}) },
    style: {
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      marginBottom: '16px'
    }
  })
  .element({
    type: 'text',
    name: 'Text 2',
    value: 'Description',
    options: { visible: true, isDesc: true, ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}) },
    style: {
      fontSize: '16px',
      textAlign: 'center',
      marginBottom: '32px'
    }
  })
  .element({
    type: 'imageselection',
    style: { hover: {} },
    options: {
      visible: true,
      columns: { default: 4, max: 6 },
      buttons: {
        fontFamily: '',
        fontSize: 12,
        color: { regular: '', hover: '', selected: '' },
        textAlign: 'center',
        textStyle: { bold: false, italic: false, underline: false, strikeThrough: false },
        background: { regular: '#FFFFFF', hover: '#FFFFFF', selected: '' },
        border: { regular: '#E5E5EA', hover: '', selected: '', width: 1, radius: 0 }
      }
    },
    attr: {
      options: [
        {
          name: 'Option',
          type: 1,
          image: ''
        },
        {
          name: 'Option',
          type: 1,
          image: ''
        }
      ]
    },
    elements: [
      createElement({
        type: 'button',
        value: 'Continue',
        attr: { class: 'w-full md:w-[280px]' },
        style: {
          minHeight: '48px',
          color: '',
          fontWeight: 'bold',
          fontSize: '14px',
          backgroundColor: '',
          borderRadius: '',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          hover: {
            backgroundColor: ''
          }
        }
      })
    ]
  })
  .done()
.done();

export const questionSection5 = createSection({
  type: 'question',
  name: 'Info Page',
  uniqName: 'InfoPage1',
  attr: { class: 'py-24 px-20 md:py-32' }
})
  .column({ colspan: 12 })
  .element({
    type: 'plain',
    attr: { class: 'flex justify-center' },
    elements: [
      createElement({
        type: 'image',
        name: 'Image',
        value: '/editor/anil-kumar-unsplash.jpg',
        attr: { class: '!max-w-full' },
        style: {
          width: '280px',
          height: '300px',
          objectFit: 'cover'
        },
        options: { visible: true }
      })
    ]
  })
  .element({
    type: 'plain',
    attr: { class: 'md:px-[10px] !mt-24 md:!mt-32' },
    elements: [
      createElement({
        type: 'text',
        name: 'Text 1',
        value: 'Info Title',
        options: { visible: true, isTitle: true, ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}) },
        style: {
          fontWeight: 'bold',
          fontSize: '16px',
          textAlign: 'center',
          marginBottom: '8px'
        }
      }),
      createElement({
        type: 'text',
        name: 'Text 2',
        value: 'Description',
        options: { visible: true, isDesc: true, ...createResponsiveFontSizeOption({tablet: 14, mobile: 14}) },
        style: {
          fontSize: '14px',
          textAlign: 'center'
        },
        attr: { class: '!mb-24 md:!mb-32' }
      })
    ]
  })
  .element({
    type: 'plain',
    attr: { class: 'md:flex md:justify-center' },
    elements: [
      createElement({
        type: 'button',
        tagName: 'button',
        value: 'Continue',
        attr: { class: 'w-full mb-32 md:w-[280px]' },
        options: { visible: true, hideable: false, allowLinks: false },
        on: { click: 'nextQuestion' },
        style: {
          minHeight: '48px',
          color: '',
          fontWeight: 'bold',
          fontSize: '14px',
          backgroundColor: '',
          borderRadius: '',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          hover: {
            backgroundColor: ''
          }
        }
      })
    ]
  })
  .done()
.done();

export const timeEntrySection = createSection({
  type: 'question',
  name: 'Survey Body',
  uniqName: 'TimeEntry1',
  attr: { class: 'pb-24 pt-[12px] px-20 md:py-32' }
})
  .column({ colspan: 12 })
  .element({
    type: 'plain',
    attr: { class: 'md:flex md:justify-center w-full !mb-[40px] mx-auto max-w-full md:!max-w-[600px]' },
    elements: [
      createElement({
        type: 'image',
        name: 'Image',
        value: '/editor/anil-kumar-unsplash.jpg',
        attr: { class: '!mx-auto' },
        style: { width: '200px', height: '200px' },
        options: { visible: false, fullSize: false }
      })
    ]
  })
  .element({
    type: 'text',
    name: 'Text 1',
    value: 'Question Title',
    options: { visible: true, isTitle: true, ...createResponsiveFontSizeOption({tablet: 20, mobile: 20}) },
    style: {
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      marginBottom: '16px'
    }
  })
  .element({
    type: 'text',
    name: 'Text 2',
    value: 'Description',
    options: { visible: true, isDesc: true, ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}) },
    style: {
      fontSize: '16px',
      textAlign: 'center',
      marginBottom: '32px'
    }
  })
  .element({
    type: 'plain',
    attr: { class: 'md:flex md:justify-center w-full' },
    elements: [
      createElement({
        type: 'timeentry',
        style: { hover: {} },
        options: {
          visible: true
        },
        elements: [
          createElement({
            type: 'button',
            value: 'Continue',
            attr: { class: 'w-full md:w-[280px]' },
            style: {
              minHeight: '48px',
              color: '',
              fontWeight: 'bold',
              fontSize: '14px',
              backgroundColor: '',
              borderRadius: '',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              hover: {
                backgroundColor: ''
              }
            }
          })
        ]
      })
    ]
  })
  .done()
.done();

export const locationPickerSection = createSection({
  type: 'question',
  name: 'Survey Body',
  uniqName: 'LocationPicker1',
  attr: { class: 'pb-24 pt-[12px] px-20 md:py-32' }
})
  .column({ colspan: 12 })
  .element({
    type: 'plain',
    attr: { class: 'md:flex md:justify-center w-full !mb-[40px] mx-auto max-w-full md:!max-w-[600px]' },
    elements: [
      createElement({
        type: 'image',
        name: 'Image',
        value: '/editor/anil-kumar-unsplash.jpg',
        attr: { class: '!mx-auto' },
        style: { width: '200px', height: '200px' },
        options: { visible: false, fullSize: false }
      })
    ]
  })
  .element({
    type: 'text',
    name: 'Text 1',
    value: 'Question Title',
    options: { visible: true, isTitle: true, ...createResponsiveFontSizeOption({tablet: 20, mobile: 20}) },
    style: {
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      marginBottom: '16px'
    }
  })
  .element({
    type: 'text',
    name: 'Text 2',
    value: 'Description',
    options: { visible: true, isDesc: true, ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}) },
    style: {
      fontSize: '16px',
      textAlign: 'center',
      marginBottom: '32px'
    }
  })
  .element({
    type: 'plain',
    attr: { class: 'md:flex md:justify-center w-full' },
    elements: [
      createElement({
        type: 'locationpicker',
        style: { hover: {} },
        options: {
          visible: true
        },
        elements: [
          createElement({
            type: 'button',
            tagName: 'button',
            value: 'Continue',
            attr: { class: 'w-full md:w-[280px]' },
            style: {
              minHeight: '48px',
              color: '',
              fontWeight: 'bold',
              fontSize: '14px',
              backgroundColor: '',
              borderRadius: '',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              hover: {
                backgroundColor: ''
              }
            }
          })
        ]
      })
    ]
  })
  .done()
.done();

export const dateEntrySection = createSection({
  type: 'question',
  name: 'Survey Body',
  uniqName: 'DateEntry1',
  attr: { class: 'pb-24 pt-[12px] px-20 md:py-32' }
})
  .column({ colspan: 12 })
  .element({
    type: 'plain',
    attr: { class: 'md:flex md:justify-center w-full !mb-[40px] mx-auto max-w-full md:!max-w-[600px]' },
    elements: [
      createElement({
        type: 'image',
        name: 'Image',
        value: '/editor/anil-kumar-unsplash.jpg',
        attr: { class: '!mx-auto' },
        style: { width: '200px', height: '200px' },
        options: { visible: false, fullSize: false }
      })
    ]
  })
  .element({
    type: 'text',
    name: 'Text 1',
    value: 'Question Title',
    options: { visible: true, isTitle: true, ...createResponsiveFontSizeOption({tablet: 20, mobile: 20}) },
    style: {
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      marginBottom: '16px'
    }
  })
  .element({
    type: 'text',
    name: 'Text 2',
    value: 'Description',
    options: { visible: true, isDesc: true, ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}) },
    style: {
      fontSize: '16px',
      textAlign: 'center',
      marginBottom: '32px'
    }
  })
  .element({
    type: 'plain',
    attr: { class: 'md:flex md:justify-center w-full' },
    elements: [
      createElement({
        type: 'dateentry',
        style: { hover: {} },
        options: {
          visible: true
        },
        elements: [
          createElement({
            type: 'button',
            value: 'Continue',
            attr: { class: 'w-full md:w-[280px]' },
            style: {
              minHeight: '48px',
              color: '',
              fontWeight: 'bold',
              fontSize: '14px',
              backgroundColor: '',
              borderRadius: '',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              hover: {
                backgroundColor: ''
              }
            }
          })
        ]
      })
    ]
  })
  .done()
.done();

export const singleRowTextSection = createSection({
  type: 'question',
  name: 'Survey Body',
  uniqName: 'SingleRowText1',
  attr: { class: 'pb-24 pt-[12px] px-20 md:py-32' }
})
  .column({ colspan: 12 })
  .element({
    type: 'plain',
    attr: { class: 'md:flex md:justify-center w-full !mb-[40px] mx-auto max-w-full md:!max-w-[600px]' },
    elements: [
      createElement({
        type: 'image',
        name: 'Image',
        value: '/editor/anil-kumar-unsplash.jpg',
        attr: { class: '!mx-auto' },
        style: { width: '200px', height: '200px' },
        options: { visible: false, fullSize: false }
      })
    ]
  })
  .element({
    type: 'text',
    name: 'Text 1',
    value: 'Question Title',
    options: { visible: true, isTitle: true, ...createResponsiveFontSizeOption({tablet: 20, mobile: 20}) },
    style: {
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      marginBottom: '16px'
    }
  })
  .element({
    type: 'text',
    name: 'Text 2',
    value: 'Description',
    options: { visible: true, isDesc: true, ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}) },
    style: {
      fontSize: '16px',
      textAlign: 'center',
      marginBottom: '32px'
    }
  })
  .element({
    type: 'plain',
    attr: { class: 'md:flex md:justify-center w-full' },
    elements: [
      createElement({
        type: 'singlerowtext',
        style: { hover: {} },
        options: {
          visible: true,
        },
        elements: [
          createElement({
            type: 'button',
            value: 'Continue',
            attr: { class: 'w-full md:w-[280px]' },
            style: {
              minHeight: '48px',
              color: '',
              fontWeight: 'bold',
              fontSize: '14px',
              backgroundColor: '',
              borderRadius: '',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              hover: {
                backgroundColor: ''
              }
            }
          })
        ]
      })
    ]
  })
  .done()
.done();

export const fileSection = createSection({
  type: 'question',
  name: 'Survey Body',
  uniqName: 'File1',
  attr: { class: 'pb-24 pt-[12px] px-20 md:py-32' }
})
  .column({ colspan: 12 })
  .element({
    type: 'text',
    name: 'Text 1',
    value: 'Question Title',
    options: { visible: true, isTitle: true, ...createResponsiveFontSizeOption({tablet: 20, mobile: 20}) },
    style: {
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      marginBottom: '16px'
    }
  })
  .element({
    type: 'text',
    name: 'Text 2',
    value: 'Description',
    options: { visible: true, isDesc: true, ...createResponsiveFontSizeOption({tablet: 16, mobile: 16}) },
    style: {
      fontSize: '16px',
      textAlign: 'center',
      marginBottom: '32px'
    }
  })
  .element({
    type: 'plain',
    attr: { class: 'md:flex md:justify-center w-full' },
    elements: [
      createElement({
        type: 'file',
        options: {
          visible: true,
        },
        elements: [
          createElement({
            type: 'button',
            value: 'Continue',
            attr: { class: 'w-full md:w-[280px]' },
            style: {
              minHeight: '48px',
              color: '',
              fontWeight: 'bold',
              fontSize: '14px',
              backgroundColor: '',
              borderRadius: '',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              hover: {
                backgroundColor: ''
              }
            }
          })
        ]
      })
    ]
  })
  .done()
.done();
