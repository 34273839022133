import { type Ref, computed, ref } from 'vue';
import {
  type Stripe,
  type StripeElements
} from '@stripe/stripe-js';
import type { Element as ElementType, PageOptions } from '@shared/types/model';
import { PaymentProvider, type PaymentForm, type PaymentPageData } from '@shared/elements/common/payment/types';
import { getTotalPayableAmount } from '@shared/elements/common/payment/utils';
import { globalEmit } from '@shared/utils/helpers';
import { useLabelOptions } from '@shared/composable/useLabelOptions';
import { $wait } from '@shared/utils/wait';

export type StripeForm = { stripe: Stripe, elements: StripeElements };

export function usePaymentElement({
  element,
  pageOptions,
  pageData
}: {
  element: Ref<ElementType>;
  pageOptions: Ref<PageOptions>;
  pageData: Ref<PaymentPageData>;
}) {
  const stripeForm = ref<StripeForm | null>(null);
  const paymentFormElement = ref<Record<string, any> | null>(null);
  const form: Ref<PaymentForm> = ref({
    email: '',
    cardNumber: '',
    cardHolder: '',
    cardExpirationDate: '',
    cardCVV: ''
  });

  const selectedPackageInfo = computed(() => pageData.value?.selectedPackage);
  const isSubmitLoading = computed(() => pageData?.value?.isSubmitLoading || false);
  const isSubmitDisabled = computed(() => pageData.value?.isSubmitDisabled || false);
  const isTrialUsed = computed(() => pageData.value?.isTrialUsed || false);
  const totalPrice = computed(() => {
    return getTotalPayableAmount(selectedPackageInfo.value, { isTrialUsed: isTrialUsed.value }) || '0.00 USD';
  });
  const subElements = computed(() => element.value.elements || []);
  const submitButtonElement = computed(() => subElements.value.find(el => el.type === 'button'));
  const isLoadingPackage = computed(() => $wait.is('getPackages'))

  const { labelStyle, isLabelVisible } = useLabelOptions({ element, pageOptions });

  const isOtherPaymentMethodsActive = computed(() => {
    // Check active methods other than Credit Card
    return !!Object.keys(pageData?.value.providerStatus || {}).filter(
      (provider) => (
        ![PaymentProvider.CREDIT_CARD, PaymentProvider.STRIPE].includes(provider as PaymentProvider) &&
        getProviderStatus(provider as PaymentProvider)
      )
    ).length;
  });

  function getProviderStatus(provider: PaymentProvider) {
    return !!pageData.value.providerStatus?.[provider];
  }

  function prepareFormValues(form: PaymentForm) {
    const cardExpirationMonth = form.cardExpirationDate?.slice(0, 2);
    const cardExpirationYear = form.cardExpirationDate ? `20${form.cardExpirationDate?.slice(2, 4)}` : '';
    const payload = {
      ...form,
      cardExpirationMonth,
      cardExpirationYear
    };
    delete payload.cardExpirationDate;
    return payload;
  }

  function handleSubmit(provider: PaymentProvider = PaymentProvider.CREDIT_CARD) {
    const payload = prepareFormValues(form.value);
    globalEmit('paymentFormSubmitted', {
      form: payload,
      provider,
      stripeForm: stripeForm.value
    });
  }

  async function clickProvider(provider: PaymentProvider) {
    const isRegisterOnPayment = pageData.value.registerOnPayment;
    const thirdPartyIntegrations: PaymentProvider[] = [
      PaymentProvider.PAYPAL,
      PaymentProvider.APPLE_PAY,
      PaymentProvider.GOOGLE_PAY
    ];
    const skipValidation = thirdPartyIntegrations.includes(provider) && !isRegisterOnPayment;

    if (paymentFormElement.value && !skipValidation) {
      pageData.value.paymentMethod = provider;
      const result = await paymentFormElement.value?.validate();
      if (!result?.valid) return false;
    }
    handleSubmit(provider);
  }

  return {
    form,
    stripeForm,
    paymentFormElement,
    handleSubmit,
    clickProvider,
    isSubmitLoading,
    isSubmitDisabled,
    isLoadingPackage,
    selectedPackageInfo,
    labelStyle,
    isLabelVisible,
    getProviderStatus,
    isOtherPaymentMethodsActive,
    totalPrice,
    submitButtonElement,
  };
}
