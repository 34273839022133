import { PageType } from '@/types';
import { parseQueryString } from './queryString';

function isAvailablePage(pageType: string) {
  const availablePages = Object.values(PageType);
  return availablePages.includes(pageType as PageType);
}

const languages = [
  'ar',
  'az',
  'zh',
  'zh_tw',
  'da',
  'en',
  'fr',
  'de',
  'he',
  'id',
  'kk',
  'ms',
  'no',
  'pt',
  'pt_br',
  'ru',
  'es',
  'es_cl',
  'es_co',
  'es_mx',
  'es_pe',
  'sv',
  'tl',
  'th',
  'tr',
  'uk',
  'vi'
];

function isActiveLang(lang: string) {
  return languages.includes(lang);
}

export function getBaseUrl(currentPath?: string) {
  const { pathname = '', search = '' } = globalThis?.location || {};
  const [restPath, hash = '' ] = (currentPath || pathname).split('#');
  const [pathParse, queryString] = restPath.split('?');
  const pathList = pathParse.split('/').filter(Boolean).reverse();
  const [first, second] = pathList;

  let slug = [...pathList].reverse().join('/');
  let lang = '';
  let pageType = '';

  if (isActiveLang(first)) {
    const [, ...restOfPath] = pathList;
    slug = restOfPath.reverse().join('/');
    lang = first;
    pageType = '';
  } else if (isActiveLang(second)) {
    const [, , ...restOfPath] = pathList;
    slug = restOfPath.reverse().join('/');
    lang = second;
    pageType = isAvailablePage(first) ? first : '';
  } else if (isAvailablePage(first)) {
    const [, ...restOfPath] = pathList;
    slug = restOfPath.reverse().join('/');
    lang = '';
    pageType = first;
  }

  if (slug === '/' || slug === '404') slug = '';
  const fullPath = [slug, lang].filter(Boolean).join('/');

  return { slug, lang, pageType, fullPath, query: queryString || search.replace(/^\?/, '') || '', hash };
}

export function goToPage(page: PageType, options?: { queryStr?: string; includeQueryString?: boolean, excludeQueryParams?: string[] }) {
  if (globalThis?.location?.href) globalThis.location.href = getClientSidePageUrl(page, options);
}

export function getClientSidePageUrl(page: PageType, options?: { queryStr?: string; includeQueryString?: boolean, excludeQueryParams?: string[] }) {
  if (process.server) return '';
  const { includeQueryString = true, excludeQueryParams } = options || {};
  let { queryStr = '' } = options || {};
  const { fullPath, query, hash } = getBaseUrl();
  let pathPrefix = fullPath === '/' ? '' : `/${fullPath}`;
  if (pathPrefix.at(-1) === '/') pathPrefix = pathPrefix.slice(0, -1);
  if (excludeQueryParams && Array.isArray(excludeQueryParams)) {
    const filteredQueryString = Object.entries(parseQueryString(queryStr || query)).reduce((acc, [key, value]) => {
      if (key && !excludeQueryParams.includes(key)) {
        acc.push(`${key}=${value}`);
      }
      return acc;
    }, [] as string[]).join('&');

    if (filteredQueryString) queryStr = `?${filteredQueryString}`;
  }

  const fullQueryStr = (includeQueryString ? queryStr : '') + (hash ? `#${hash}` : '');
  return `${pathPrefix}${page && page !== PageType.LANDING ? `/${page}` : ``}${fullQueryStr}`;
}
