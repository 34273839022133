import { createSection, createElement, createResponsiveFontSizeOption } from '@shared/utils/section';
export * from './question';
export * from './progress';
export * from './register';
export * from './payment';
export * from './paywall';
export * from './surveyEnding';
export * from './paymentSuccess';

export const header = createSection({
  type: 'header',
  uniqName: 'HeaderCenter',
  style: { boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.1)', zIndex: '1' }
})
  .column({
    colspan: 12,
    attr: { class: 'flex p-[13px] md:p-[20px] justify-center' }
  })
  .element({
    type: 'image',
    name: 'Image',
    value: '/editor/logo.png',
    style: { width: '87px', height: '32px' },
    attr: { class: 'ignore-h-auto !w-auto lg:!w-[var(--imageW)] !h-[calc(var(--imageH)/2)] md:!h-[calc(var(--imageH)*0.7)] lg:!h-[var(--imageH)]' }
  })
  .done()
  .done();

export const headerRight = createSection({
  type: 'header',
  uniqName: 'HeaderRight',
  style: { boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.1)', zIndex: '1' },
  attr: { class: 'flex-container' }
})
  .column({
    colspan: 12,
    attr: { class: 'flex p-[13px] md:p-[20px] justify-end' }
  })
  .element({
    type: 'image',
    name: 'Image',
    value: '/editor/logo.png',
    style: { width: '87px', height: '32px' },
    attr: { class: 'ignore-h-auto !w-auto lg:!w-[var(--imageW)] !h-[calc(var(--imageH)/2)] md:!h-[calc(var(--imageH)*0.7)] lg:!h-[var(--imageH)]' }
  })
  .done()
  .done();

export const headerLeft = createSection({
  type: 'header',
  uniqName: 'HeaderLeft',
  style: { boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.1)', zIndex: '1' },
  attr: { class: 'flex-container' }
})
  .column({
    colspan: 12,
    attr: { class: 'flex p-[13px] md:p-[20px] justify-start' }
  })
  .element({
    type: 'image',
    name: 'Image',
    value: '/editor/logo.png',
    style: { width: '87px', height: '32px' },
    attr: { class: 'ignore-h-auto !w-auto lg:!w-[var(--imageW)] !h-[calc(var(--imageH)/2)] md:!h-[calc(var(--imageH)*0.7)] lg:!h-[var(--imageH)]' }
  })
  .done()
  .done();

export const body = createSection({
  type: 'body',
  uniqName: 'Body1',
})
  .column({
    colspan: 6,
    attr: {
      class: 'order-3 lg:order-1 xl:col-span-4 md:pt-[56px] flex flex-col justify-center py-32 px-16 lg:pr-16 md:!px-[182px] lg:!px-32'
    }
  })
  .element({
    type: 'text',
    name: 'Text 1',
    tagName: 'h1',
    value: 'Welcome',
    style: {
      color: '',
      lineHeight: 'normal !important',
      fontWeight: 'bold'
    },
    options: {
      visible: true,
      ...createResponsiveFontSizeOption({ mobile: 24, tablet: 32 })
    },
    attr: { class: '!text-24 md:!text-32 md:text-center lg:text-left !mb-12 md:!mb-16' }
  })
  .element({
    type: 'text',
    name: 'Text 2',
    value:
      'Welcome visitors to your site with a short, engaging introduction. Double click to edit and add your own text.',
    style: { color: '', marginBottom: '32px', lineHeight: '1.5 !important' },
    options: {
      visible: true,
      ...createResponsiveFontSizeOption({ mobile: 14, tablet: 16 })
    },
    attr: { class: '!text-14 md:!text-16 md:text-center lg:text-left' }
  })
  .element({
    type: 'plain',
    attr: {
      class:
        'flex flex-col md:flex-row gap-20 md:gap-12 lg:gap-24 mb-24 md:items-center md:justify-center lg:justify-start'
    },
    elements: [
      createElement({
        type: 'button',
        name: 'Button 1',
        value: 'Start Now',
        attr: {
          class: 'w-full md:min-w-[150px] min-h-[48px] p-13 inline-flex items-center justify-center',
        },
        wrapperClass: 'w-full',
        style: {
          color: '',
          backgroundColor: '',
          fontSize: '14px',
          fontWeight: '600',
          borderRadius: ''
        }
      }),
      createElement({
        type: 'button',
        name: 'Button 2',
        value: 'Learn More',
        attr: {
          class: 'w-full md:min-w-[150px] min-h-[48px] p-13 inline-flex items-center justify-center',
        },
        wrapperClass: 'w-full',
        style: {
          color: '',
          backgroundColor: '',
          fontSize: '14px',
          fontWeight: '600',
          borderRadius: ''
        }
      })
    ]
  })
  .element({
    type: 'text',
    name: 'Text 3',
    value:
      'We guarantee to keep your information private. As you continue, you will accept the <a href="#" style="color: #007AFF;">privacy policy</a> and the <a href="#" style="color: #007AFF;">terms of conditions</a>.',
    options: {
      visible: true,
      customizeFontColor: true,
      ...createResponsiveFontSizeOption({ mobile: 11, tablet: 11 })
    },
    style: { color: '#A3A3A3', fontSize: '11px', lineHeight: '1.5' },
    attr: { class: 'md:text-center lg:text-left' }
  })
  .done()
  .column({
    colspan: 2,
    attr: { class: 'order-2 lg:order-2 hidden xl:flex' }
  })
  .done()
  .column({
    colspan: 6,
    attr: { class: 'order-1 lg:order-3 flex items-center justify-center pb-8 md:pb-0' }
  })
  .element({
    type: 'image',
    name: 'Image',
    value: '/editor/anil-kumar-unsplash.jpg',
    attr: { class: '!mx-auto max-w-full md:!max-w-[600px] lg:!max-w-full' },
    style: { width: '100%' },
    options: { visible: true, fullSize: true }
  })
  .done()
  .done();


export const body2 = createSection({
  type: 'body',
  uniqName: 'Body2',
})
  .column({
    colspan: 12,
    attr: {
      class: 'col-span-4 max-w-[540px] my-0 mx-auto md:pt-[56px] flex flex-col justify-center py-32 px-16 md:pr-16 md:!px-[32px]'
    }
  })
  .element({
    type: 'text',
    name: 'Text 1',
    tagName: 'h1',
    value: 'Welcome',
    style: {
      color: '',
      lineHeight: 'normal !important',
      fontWeight: 'bold'
    },
    options: {
      visible: true,
      ...createResponsiveFontSizeOption({ mobile: 24, tablet: 32 })
    },
    attr: { class: '!text-24 md:!text-32 text-center !mb-12 md:!mb-16' }
  })
  .element({
    type: 'text',
    name: 'Text 2',
    value:
      'Welcome visitors to your site with a short, engaging introduction. Double click to edit and add your own text.',
    style: { color: '', marginBottom: '32px', lineHeight: '1.5 !important' },
    options: {
      visible: true,
      ...createResponsiveFontSizeOption({ mobile: 14, tablet: 16 })
    },
    attr: { class: '!text-14 md:!text-16 text-center' }
  })
  .element({
    type: 'plain',
    attr: {
      class:
        'flex flex-col w-full md:flex-row gap-20 md:gap-12 lg:gap-24 mb-24 md:items-center md:justify-center lg:justify-start'
    },
    elements: [
      createElement({
        type: 'button',
        name: 'Button 1',
        value: 'Start Now',
        attr: {
          class: 'w-full md:min-w-[150px] min-h-[48px] p-13 inline-flex items-center justify-center',
        },
        wrapperClass: 'w-full',
        style: {
          color: '',
          backgroundColor: '',
          fontSize: '14px',
          fontWeight: '600',
          borderRadius: ''
        }
      }),
      createElement({
        type: 'button',
        name: 'Button 2',
        value: 'Learn More',
        attr: {
          class: 'w-full md:min-w-[150px] min-h-[48px] p-13 inline-flex items-center justify-center',
        },
        wrapperClass: 'w-full',
        style: {
          color: '',
          backgroundColor: '',
          fontSize: '14px',
          fontWeight: '600',
          borderRadius: ''
        }
      })
    ]
  })
  .element({
    type: 'text',
    name: 'Text 3',
    value:
      'We guarantee to keep your information private. As you continue, you will accept the <a href="#" style="color: #007AFF;">privacy policy</a> and the <a href="#" style="color: #007AFF;">terms of conditions</a>.',
    options: {
      visible: true,
      customizeFontColor: true,
      ...createResponsiveFontSizeOption({ mobile: 11, tablet: 11 })
    },
    style: { color: '#A3A3A3', fontSize: '11px' },
    attr: { class: 'text-center' }
  })
  .done()
  .done()

export const body3 =  createSection({
  type: 'body',
  uniqName: 'Body3',
})
  .column({
    colspan: 6,
    attr: { class: 'order-1 lg:order-1 flex items-center justify-center pb-8 md:pb-0' }
  })
  .element({
    type: 'image',
    name: 'Image',
    value: '/editor/anil-kumar-unsplash.jpg',
    attr: { class: '!mx-auto max-w-full md:!max-w-[600px] lg:!max-w-full' },
    style: { width: '100%' },
    options: { visible: true, fullSize: true }
  })
  .done()
  .column({
    colspan: 5,
    attr: {
      class: 'order-1 lg:order-3 xl:col-span-4 md:pt-[56px] flex flex-col justify-center py-32 px-16 lg:pr-16 md:!px-[182px] lg:!px-32'
    }
  })
  .element({
    type: 'text',
    name: 'Text 1',
    tagName: 'h1',
    value: 'Welcome',
    style: {
      color: '',
      lineHeight: 'normal !important',
      fontWeight: 'bold'
    },
    options: {
      visible: true,
      ...createResponsiveFontSizeOption({ mobile: 24, tablet: 32 })
    },
    attr: { class: '!text-24 md:!text-32 md:text-center lg:text-left !mb-12 md:!mb-16' }
  })
  .element({
    type: 'text',
    name: 'Text 2',
    value:
      'Welcome visitors to your site with a short, engaging introduction. Double click to edit and add your own text.',
    style: { color: '', marginBottom: '32px', lineHeight: '1.5 !important' },
    options: {
      visible: true,
      ...createResponsiveFontSizeOption({ mobile: 14, tablet: 16 })
    },
    attr: { class: '!text-14 md:!text-16 md:text-center lg:text-left' }
  })
  .element({
    type: 'plain',
    attr: {
      class:
        'flex flex-col md:flex-row gap-20 md:gap-12 lg:gap-24 mb-24 md:items-center md:justify-center lg:justify-start'
    },
    elements: [
      createElement({
        type: 'button',
        name: 'Button 1',
        value: 'Start Now',
        attr: {
          class: 'w-full md:min-w-[150px] min-h-[48px] p-13 inline-flex items-center justify-center',
        },
        wrapperClass: 'w-full',
        style: {
          color: '',
          backgroundColor: '',
          fontSize: '14px',
          fontWeight: '600',
          borderRadius: ''
        }
      }),
      createElement({
        type: 'button',
        name: 'Button 2',
        value: 'Learn More',
        attr: {
          class: 'w-full md:min-w-[150px] min-h-[48px] p-13 inline-flex items-center justify-center',
        },
        wrapperClass: 'w-full',
        style: {
          color: '',
          backgroundColor: '',
          fontSize: '14px',
          fontWeight: '600',
          borderRadius: ''
        }
      })
    ]
  })
  .element({
    type: 'text',
    name: 'Text 3',
    value:
      'We guarantee to keep your information private. As you continue, you will accept the <a href="#" style="color: #007AFF;">privacy policy</a> and the <a href="#" style="color: #007AFF;">terms of conditions</a>.',
    options: {
      visible: true,
      customizeFontColor: true,
      ...createResponsiveFontSizeOption({ mobile: 11, tablet: 11 })
    },
    style: { color: '#A3A3A3', fontSize: '11px' },
    attr: { class: 'md:text-center lg:text-left' }
  })
  .done()
  .column({
    colspan: 1,
    attr: { class: 'order-3 lg:order-1 hidden xl:flex' }
  })
  .done()
  .done();


export const footer = createSection({
  type: 'footer',
  uniqName: 'Footer',
  style: { zIndex: '1' }
})
  .column({
    colspan: 12,
    attr: { class: 'p-16 md:p-32' }
  })
  .element({
    type: 'text',
    name: 'text',
    value: 'My Site',
    style: { color: '', marginBottom: '24px', fontWeight: 'bold', lineHeight: 'normal !important' },
    options: {
      visible: true,
      ...createResponsiveFontSizeOption({ mobile: 14, tablet: 14 })
    },
    attr: { class: '!text-14 text-center' }
  })
  .element({
    type: 'plain',
    attr: {
      class:
        'flex flex-col md:flex-row gap-12 md:gap-24 lg:gap-32 mb-24 items-center md:justify-center'
    },
    elements: [
      createElement({
        type: 'text',
        name: 'Footer Text 1',
        value: 'Terms of Service',
        style: { color: '', fontSize: '12px' },
        options: {
          visible: true,
          ...createResponsiveFontSizeOption({ mobile: 12, tablet: 12 })
        },
      }),
      createElement({
        type: 'text',
        name: 'Footer Text 2',
        value: 'Privacy Policy',
        style: { color: '', fontSize: '12px' },
        options: {
          visible: true,
          ...createResponsiveFontSizeOption({ mobile: 12, tablet: 12 })
        },
      }),
      createElement({
        type: 'text',
        name: 'Footer Text 3',
        value: 'Support Center',
        style: { color: '', fontSize: '12px' },
        options: {
          visible: true,
          ...createResponsiveFontSizeOption({ mobile: 12, tablet: 12 })
        },
      })
    ]
  })
  .element({
    type: 'text',
    name: 'text',
    value: 'All rights reserved. © 2023',
    style: { color: '#818A9C', fontSize: '10px' },
    attr: { class: 'text-center' }
  })
  .done()
  .done();

export const testimonials = createSection({
  type: 'body',
  uniqName: 'Testimonials1',
  options: {
    visible: true,
    removable: true,
    spacing: {
      mobile: { top: 24, left: 24, bottom: 24, right: 24 },
      tablet: { top: 32, left: 40, bottom: 32, right: 40 },
      desktop: { top: 32, left: 0, bottom: 32, right: 0 }
    }
  }
})
  .column({
    colspan: 12
  })
    .element({
      type: 'text',
      name: 'Text 1',
      value: 'Title',
      options: {
        visible: true,
        isTitle: true,
        ...createResponsiveFontSizeOption({ mobile: 20, tablet: 20 })
      },
      attr: { class: '!mb-8 md:!mb-16' },
      style: {
        fontWeight: 'bold',
        fontSize: '20px',
        textAlign: 'center'
      }
    })
    .element({
      type: 'text',
      name: 'Text 2',
      value: 'Description',
      options: {
        visible: true,
        isDesc: true,
        ...createResponsiveFontSizeOption({ mobile: 16, tablet: 16 })
      },
      attr: { class: '!mb-32 md:!mb-[56px]' },
      style: {
        fontSize: '16px',
        textAlign: 'center'
      }
    })
    .element({
      type: 'testimonials',
      style: {
        fontSize: '14px',
        borderRadius: '8px',
        textAlign: 'center'
      }
    })
    .done()
  .done();
