import { createI18n } from 'vue-i18n';
import { useSiteStore } from '@/stores/site';
import type { Pinia } from '@pinia/nuxt/dist/runtime/composables';

export default defineNuxtPlugin(async ({ hook }) => {
  hook('app:created', (vueApp) => {
    const { $pinia } = useNuxtApp();
    const { siteData } = useSiteStore($pinia as Pinia)
    const { lang, locales } = siteData || { lang: 'en', locales: {} }

    const i18n = createI18n({
      legacy: false,
      locale: lang,
      messages: { [lang] : locales }
    });
  
    vueApp.use(i18n);
  })
});
