import { createSection, createElement, createResponsiveFontSizeOption } from '@shared/utils/section';

export const registerSection1 = createSection({
  type: 'register',
  name: 'Registration',
  uniqName: 'Register1',
  options: {
    visible: true,
    removable: false,
    hideable: false
  },
  attr: { class: 'px-4 sm:px-20 py-32' }
})
  .column({ colspan: 12, attr: { class: 'max-w-[430px] w-full my-0 mx-auto' } })
  .element({
    type: 'text',
    value: 'Please enter your email address',
    name: 'Title',
    options: {
      visible: true,
      ...createResponsiveFontSizeOption({ tablet: 20, mobile: 20 })
    },
    attr: { class: '!mx-16 sm:!mx-0' },
    style: {
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      marginBottom: '32px'
    }
  })
  .element({
    type: 'registration',
    name: 'Registration Form',
    style: {
      borderWidth: '1px',
      borderColor: '#E7EAEE',
      borderRadius: '6px',
      backgroundColor: '#FFFFFF'
    },
    options: {
      visible: true,
      hideable: false
    },
    elements: [
      createElement({
        type: 'button',
        value: 'Continue',
        tagName: 'button',
        attr: { class: 'w-full', type: 'submit' },
        options: {
          visible: true,
          hideable: false
        },
        style: {
          minHeight: '48px',
          color: '',
          fontWeight: 'bold',
          fontSize: '14px',
          backgroundColor: '',
          borderRadius: '',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          hover: {
            backgroundColor: ''
          }
        }
      }),
      createElement({
        type: 'text',
        slotName: 'titleText',
        value: 'Your account will be created with this e-mail address.',
        options: {
          visible: true,
          allowLinks: true,
          ...createResponsiveFontSizeOption({ tablet: 16, mobile: 16 })
        },
        style: {
          fontSize: '16px',
          textAlign: 'center',
          color: '',
          marginTop: '8px',
          marginBottom: '24px',
          fontWeight: 'bold'
        }
      })
    ]
  })
  .element({
    type: 'text',
    name: 'Footer Text',
    value:
      'We guarantee the security of your data. You will be notified of updates about your account via this email. By proceeding you agree to the <a href="#" target="_blank" style="color: #007AFF;">Terms of Use</a> and <a href="#" target="_blank" style="color: #007AFF;">Privacy Policy</a>',
    options: {
      visible: true,
      hideable: false,
      customizeFontColor: true,
      ...createResponsiveFontSizeOption({ tablet: 10, mobile: 10 })
    },
    attr: { class: '!mx-16 sm:!mx-0' },
    style: {
      fontSize: '10px',
      marginTop: '32px',
      color: '#737380'
    }
  })
  .done()
  .done();
